import axios from "axios";
const baseurl = "https://asia-southeast2-paotam-platform.cloudfunctions.net/";
// const baseurl = "http://localhost:5000/paotam-platform/asia-southeast2/";

export async function get_data() {
  const response = {
    data: {
      gdp_30_day: 0,
      gdp_7_day: 0,
      gdp_today: 0,
      gdp_yesterday: 0,
      total_gdp: 0,
    },
  };
  return response.data;
}

export async function get_geolocations() {
  const response = {
    data: {
      type: "procressed",
    },
  };
  console.log(response.data);
  return response.data;
}
