import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Textbox from "../components/Textbox";
import Mapcover from "../components/Mapcover";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { get_data, get_geolocations } from "../services/api";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  height: "100%",
}));
function Map() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [data, setData] = useState({
    gdp_30_day: -1,
  });
  const [geolocations, setGeolocations] = useState({
    type: "procressed",
  });
  const [show, setShow] = useState(true);

  const [time, setTime] = useState(Date.now());

  const [lightPoleData, setLightPoleData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      get_data().then((res) => {
        setData(res);
      });
      setTime(Date.now());
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // console.log(geolocations.type === "procressed");
    if (geolocations.type === "procressed" || data.gdp_30_day === -1) {
      get_data().then((res) => {
        setData(res);
      });
      get_geolocations().then((res) => {
        setGeolocations(res);
      });
    }
  }, [geolocations.type, data.gdp_30_day]);

  // if (geolocations.type === "procressed" || data.gdp_30_day === -1) {
  //   return (
  //     <Stack
  //       direction="row"
  //       justifyContent="center"
  //       alignItems="center"
  //       spacing={2}
  //       className="w-100 h-100"
  //       style={{
  //         backgroundColor: "#000000",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Stack>
  //   );
  // }

  if (isDesktop) {
    return (
      <Container sx={{ flexGrow: 1 }}>
        <Grid
          style={{ backgroundColor: "#0a1631" }}
          className="h-100"
          container
          spacing={2}
        >
          <Textbox
            lightPoleData={lightPoleData}
            isDesktop={isDesktop}
            data={data}
          />
          <Grid item xs={12}>
            <Mapcover
              setLightPoleData={setLightPoleData}
              geolocations={geolocations}
              isDesktop={isDesktop}
            />
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container sx={{ flexGrow: 1 }}>
        <Grid container className="w-100 h-100" direction="column" spacing={2}>
          <Grid item xs={12}>
            <Mapcover
              setLightPoleData={setLightPoleData}
              show={show}
              onClick={() => setShow(!show)}
              geolocations={geolocations}
              isDesktop={isDesktop}
            />
          </Grid>
          <Dialog
            fullScreen
            open={show}
            onClose={() => setShow(!show)}
            TransitionComponent={Transition}
            style={{ height: "80%", top: "20%" }}
            PaperProps={{
              style: { borderRadius: "20px 20px 0 0" },
            }}
          >
            <DialogContent style={{ paddingTop: "6%", overflow: "hidden" }}>
              <Textbox
                lightPoleData={lightPoleData}
                isDesktop={isDesktop}
                data={data}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Container>
    );
  }
}

export default Map;
