import React from "react";
import { Row, Col } from "react-bootstrap";

function Boxset(props) {
  // console.log("Boxset props: ", props);
  const { Title, TitleColor, Description, DescriptionColor } = props;
  return (
    <Row>
      <Col>
        <Row>
          <Col
            style={{
              color: TitleColor,
            }}
            className="text-1"
          >
            {Title}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-2">{props.children}</Col>
        </Row>
        <Row className="mt-2">
          <Col
            style={{
              color: DescriptionColor,
            }}
            className="text-1"
          >
            {Description}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Boxset;
