import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGlyYWpldHciLCJhIjoiY2w0bDhrd3YyMWswazNxb2IwZzNvN2o0cSJ9.Gd-zworzRNaBLF_Yifh27Q";

function Mapcover(props) {
  const { setLightPoleData } = props;
  const [deviceData, setDeviceData] = useState("offline");
  const [data, setData] = useState([
    {
      name: "เสาไฟหน้าเทศบาล ต้นที่ 1",
      status: "",
      coordinates: [100.14146959956587, 18.1454355239182],
    },
    {
      name: "เสาไฟหน้าเทศบาล ต้นที่ 2",
      status: "",
      coordinates: [100.14174155257805, 18.14560085851309],
    },
  ]);

  const fetchData = async () => {
    try {
      const list = [
        "RngrS515_GTHjipeqYYvm_5bxdbLamIs",
        "qq7gAfP0OkTR-JTWT9fxrLMwcS42xPKF",
      ];
      const version = "v2";
      const current_data = data;

      list.forEach(async (item, idx) => {
        const res = await fetch(
          "https://sgp1.blynk.cloud/external/api/get?token=" +
            item +
            `&${version}`
        );
        const res_data = await res.json();
        if (res_data == 1) {
          // console.log("Device " + idx + " is alarm");
          current_data[idx].status = "alarm";
        } else {
          const response = await fetch(
            "https://sgp1.blynk.cloud/external/api/isHardwareConnected?token=" +
              item
          );
          const response_data = await response.json();
          if (response_data) {
            // console.log("Device " + idx + " is online");
            current_data[idx].status = "normal";
          } else {
            // console.log("Device " + idx + " is offline");
            current_data[idx].status = "offline";
          }
        }
      });
      // update on mapbox
      // console.log("current_data: ", current_data);
      setLightPoleData([...current_data]);
      setData([...current_data]);

      current_data.forEach((point, idx) => {
        let markerColor;

        switch (point.status) {
          case "normal":
            markerColor = "lightGreen";
            break;
          case "alarm":
            markerColor = "red";
            break;
          case "offline":
            markerColor = "gray";
            break;
          default:
            markerColor = "gray";
            break;
        }
        // console.log(`markerColor${idx}: `, markerColor);
        if (document.getElementsByClassName("marker-" + `${idx}`).length > 0) {
          const div = document.getElementsByClassName("marker-" + `${idx}`)[0];
          // const nameEle = document.getElementsByClassName(`point-name-${idx}`)[0];
          const statusEle = document.getElementsByClassName(
            `point-status-${idx}`
          )[0];
          div.style.backgroundColor = markerColor;
          // nameEle.style.backgroundColor = markerColor;
          statusEle.style.color = markerColor;
          // nameEle.innerHTML = point.name;
          statusEle.innerHTML = `${point.name} (${point.status})`;
        }
      });
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();

    // set second parameter
    const intervalId = setInterval(fetchData, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    const isNightTime = hour >= 18 || hour < 6;

    const map = new mapboxgl.Map({
      container: "map",
      style: isNightTime
        ? "mapbox://styles/mapbox/dark-v10"
        : "mapbox://styles/mapbox/streets-v11",
      center: [100.14146959956587, 18.1454355239182],
      zoom: 17,
    });

    data.forEach((point, idx) => {
      let markerColor;

      switch (point.status) {
        case "normal":
          markerColor = "green";
          break;
        case "alarm":
          markerColor = "red";
          break;
        case "offline":
          markerColor = "gray";
          break;
        default:
          markerColor = "gray";
          break;
      }

      const el = document.createElement("div");
      el.className = "marker marker-" + `${idx}`;
      el.style.backgroundColor = markerColor;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<h3 class="point-name-${idx}">${point.name}</h3><p class="point-status-${idx}">Status: ${point.status}</p>`
      );

      new mapboxgl.Marker(el)
        .setLngLat(point.coordinates)
        .setPopup(popup)
        .addTo(map);
    });

    // Add labels under the markers
    data.forEach((point, idx) => {
      const label = document.createElement("div");

      // if point is alarm, set label color to red and bold, else not show label
      if (point.status === "alarm") {
        label.className = "label-alarm " + `point-status-${idx}`;
        label.innerHTML = point.name + " (Problem)";
        label.style.color = "red";
        label.style.fontSize = "0.8rem";
        label.style.paddingTop = "2rem";
        label.style.fontWeight = "bold";
      } else if (point.status === "offline") {
        label.className = "label-offline " + `point-status-${idx}`;
        label.innerHTML = point.name + " (Offline)";
        label.style.color = "gray";
        label.style.fontSize = "0.8rem";
        label.style.paddingTop = "2rem";
        // label.style.fontWeight = "bold";
      } else {
        label.className = "label " + `point-status-${idx}`;
        label.innerHTML = point.name;
        label.style.color = "black";
        label.style.fontSize = "0.8rem";
        label.style.paddingTop = "2rem";
      }

      new mapboxgl.Marker(label).setLngLat(point.coordinates).addTo(map);
    });

    map.on("click", (e) => {
      const coordinates = e.lngLat;
      const popup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`<p>Coordinates: ${coordinates.lng}, ${coordinates.lat}</p>`)
        .addTo(map);
    });

    return () => {
      map.remove();
    };
  }, []);

  return (
    <div
      id="map"
      style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
    />
  );
}

export default Mapcover;
