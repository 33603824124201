import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Boxset from "./Boxset";
import Stack from "@mui/material/Stack";

function Textbox(props) {
  const { data, lightPoleData, isDesktop } = props;
  const [alarm, setAlarm] = useState(0);
  const [offline, setOffline] = useState(0);
  console.log("lightPoleData : ", lightPoleData);
  // number of lightPoleData[].status =
  React.useEffect(() => {
    if (lightPoleData) {
      const alarm = lightPoleData.filter(
        (item) => item.status === "alarm"
      ).length;
      // number of lightPoleData[].status = offline
      const offline = lightPoleData.filter(
        (item) => item.status === "offline"
      ).length;
      setAlarm(alarm);
      setOffline(offline);
    }
  }, [lightPoleData]);

  if (!lightPoleData) return null;

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Row
        className="h-100 set-w-100"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 999,
          margin: "auto",
        }}
      >
        <Col className={isDesktop ? "h-100" : "h-25 pb-2"}>
          <Row className={`b-bg`}>
            <Col className="pt-set">
              <Row>
                <Col
                  className="mp-set"
                  style={{
                    color: !isDesktop ? "black" : "white",
                    fontSize: "1.875rem",
                    lineHeight: "2.25rem",
                    fontWeight: "400",
                  }}
                >
                  AIS Light Monitoring Dashboard
                </Col>
              </Row>
              <Row>
                <Col
                  className="mp-set"
                  style={{
                    color: !isDesktop ? "black" : "white",
                    fontWeight: "400",
                  }}
                >
                  Powered by Techmorrow co.,ltd.
                  <br />
                  <a
                    href="https://techmorrow.co/"
                    style={{ color: "#29d392" }}
                    target="_blank"
                  >
                    Discover more..
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              height: "70%",
              paddingTop: "2%",
              backgroundColor: "white",
            }}
          >
            <Col>
              <Row>
                <Col className="box-dashboard mp-set">
                  <Boxset
                    Title="ค่าไฟที่ใช้ไป (บาท)"
                    TitleColor="#9697bf"
                    Description={`+0%`}
                    DescriptionColor="#38c690"
                  >
                    -
                  </Boxset>
                </Col>
              </Row>
              <Row>
                <Col className="box-dashboard mp-set">
                  <Boxset
                    Title="จำนวนไฟถนนทั้งหมด"
                    TitleColor="#9697bf"
                    Description={`-`}
                    DescriptionColor="#38c690"
                  >
                    {lightPoleData.length}
                  </Boxset>
                </Col>
                <Col className="box-dashboard mp-set">
                  <Boxset
                    Title="ไฟถนนที่เกิดปัญหา"
                    TitleColor="#9697bf"
                    Description={`-`}
                    DescriptionColor="#FF0000"
                  >
                    {alarm}
                  </Boxset>
                </Col>
              </Row>
              <Row>
                <Col className="box-dashboard mp-set">
                  <Boxset
                    Title="offline"
                    TitleColor="#9697bf"
                    DescriptionColor="#38c690"
                  >
                    {offline}
                  </Boxset>
                </Col>
                <Col className="box-dashboard mp-set">
                  <Boxset
                    Title="-"
                    TitleColor="#9697bf"
                    DescriptionColor="#38c690"
                  >
                    -
                  </Boxset>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Stack>
  );
}

export default Textbox;
